//* React Imports
import { Route, Routes, Navigate } from "react-router-dom";

//* Components imports
import PublicRoutes from "./PublicRoutes";

export const Main = () => {
  return (
    <Routes>
      <Route path={"/*"} element={<PublicRoutes />} />
    </Routes>
  );
};
